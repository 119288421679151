a.contrast {
  text-decoration: none;
}

.header {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.header > img {
  width: 100px;
  height: 100px;
  border-radius: 1000px;
}

.header > hgroup {
  margin: 0px;
}

.hidden-if-empty:empty {
  display: none;
}
